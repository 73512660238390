<template>
<!--  -->
	<div>
		<div class="container darkBack">
			<div class="whiteFont font20">履职类型排行</div>
			<div id="sinceType"></div>
		</div>
	</div>
</template>

<script>
import {getAjax, timestamp} from "/src/assets/js/websocket";

	export default {
		data() {
			return{
				resumption:{
					yAxis:['游泳', '艺术体操', '举重', '柔道', '篮球','射击','自行车','赛艇','划水'],
					data:[],
				}
			}
		},
		methods:{
			// 绘制履职类型排行表
			drawSinceType(number){
				let that = this;
				// let myChart = this.$echarts.init(document.getElementById("sinceType"));
				
				let myChart = this.$echarts.getInstanceByDom(document.getElementById("sinceType"))
				if (myChart == null) { // 如果不存在，就进行初始化
					myChart = this.$echarts.init(document.getElementById("sinceType"));
				}

				let option = {
					xAxis: {
						max: 'dataMax',
						// 显示网格线
						splitLine:{
							show: true,
							lineStyle:{
								color:'#EAB03133',
							}
						},
						// 不显示刻度值
						axisLabel:{show:false}
					},
					grid: {         // 间距
						left: '6%',  
						right: '12%', 
						top:'8%', 
						bottom: '6%',  
						containLabel: true,
					},
					yAxis: {
						type: 'category',
						data: number.yAxis,
						inverse: true,
						animationDuration: 300,
						animationDurationUpdate: 300,
						//y轴显示及颜色
						axisLine:{
							show:true,
							lineStyle:{
								color:'#EAB031',
							}
						},
						// 刻度不显示
						axisTick:{show:false},
						// y轴字体样式
						axisLabel:{
							color:'#7EA7E2',
							fontSize:'0.14rem'
						},
					},
					series: [{
						// 实时排序
						realtimeSort: true,
						type: 'bar',
						data: number.data,
						// 柱子宽度
						barWidth:'40%',
						// 柱子间距
						barCategoryGap:10,
						// 数据显示
						label: {
							show: true,
							position: 'right',
							color:"#F1F1F1",
							valueAnimation: true,
                            fontSize:'0.14rem',
						},
						itemStyle: {
							normal: {
								color: this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0,
									color: "#E5813A" // 0% 处的颜色
								}, {
									offset: 1,
									color: "#EFDC29" // 100% 处的颜色
								}], false)
							}
						},
					}],
					animationDuration: 0,
					animationDurationUpdate: 3000,
					animationEasing: 'linear',
					animationEasingUpdate: 'linear'
				};
				
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
				  myChart.resize();
				});
			},
			// 获取指导员数据
			getInstructor(){
				let that = this;
				var data = {
					token: window.getToken(),
					app_id: window.appId,
					time: timestamp(),
				}
				getAjax({
					url: "/map/screen/getHomeSocialSportsInstructor",
					method:"GET",
					data:data,
					success:function(res){
						// console.log(res)
						that.resumption = {
							yAxis:[],
							data:[],
						}
						res.data.xunjing.forEach((item,index)=>{
							that.resumption.yAxis.push(item.title);
							that.resumption.data.push(item.num);
						})
						that.drawSinceType(that.resumption);
					},
				})
			}
		},
		mounted() {
			let that = this;
			that.getInstructor();
			// that.drawSinceType(this.resumption);
		}
	}
</script>

<style scoped>
	.container{
		width: 5.94rem;
		height: 3.1rem;
		padding: 0.2rem;
		position: relative;
	}
	#sinceType{
		width: 5.80rem;
		height: 2.5rem;
		/* border:1px solid red; */
		margin-left:-0.2rem;
	}
</style>